.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
}
.hero-label {
  border-radius: 50px;
  border: 1px solid var(--dark-grey, #272727);
  display: inline-flex;
  padding: 10px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.hero-title {
  color: var(--dark-grey, #272727);
  font-family: Montserrat;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}
.hero-title-green {
  color: var(--green, #57e0b2);
  font-family: Montserrat;
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.hero-img {
}
.hero-btn-box {
  display: inline-flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 25px;
  border-radius: 50px;
  background: var(--white, #f9f9f9);
  position: absolute;
  bottom: 9%;
}
.hero-cta-black {
  color: var(--dark-grey, #272727);
  font-family: var(--montserrat);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 50px;
  border: 2px solid var(--dark-grey, #272727);
  display: flex;
  width: 280px;
  padding: 10px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.hero-cta-green {
  color: var(--white, #f9f9f9);
  font-family: var(--montserrat);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  width: 280px;
  height: 80px;
  padding: 10px 25px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 50px;
  background: var(--green, #57e0b2);
}
