.testimonials-section {
    display: flex;
    width: 100vw;
    padding: 100px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    overflow: hidden;
    border-radius: 50px;
    background: var(--dark-grey, #272727);
  }
  
  .testimonials-top {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    max-width: 860px;

  }
  
  .testimonials-title {
    color: var(--white, #F9F9F9);
    text-align: center;
    font-family: Montserrat;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .testimonials-title-green {
    color: var(--green, #57E0B2);
    font-family: Montserrat;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .testimonials-description {
    color: #FFF;
    text-align: center;
    font-family: var(--inter);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .testimonials-bottom {
    position: relative;
    width: 100%;
  }
  
  .testimonials-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
  }
  
  .testimonials-slider.animating {
    transition: transform 0.5s ease-in-out;
  }
  
  .testimonial-card {
    background: #3B3B3B;
    border-radius: 15px;
    padding: 20px;
    margin: 0 10px;
    min-width: 30%;
    max-width: 30%;
    opacity: 0.5;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    position: relative;
    text-align: left;
    box-sizing: border-box;
  }
  
  .testimonial-card.active {
    transform: translateY(-20px) scale(1.05);
    opacity: 1;
    z-index: 2;
  }
  
  .testimonial-card.next, .testimonial-card.prev {
    transform: scale(0.9);
    opacity: 0.5;
  }
  
  .testimonial-stars {
    font-size: 24px;
    color: #FFD700;
  }
  
  .testimonial-comment {
    color: #FFF;
    margin: 10px 0;
    font-size: 16px;
    font-family: var(--inter);

  }
  
  .testimonial-profile {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .testimonial-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .testimonial-info {
    text-align: left;
  }
  
  .testimonial-name {
    color: #FFF;
font-family: var(--inter);
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
  }
  
  .testimonial-position {
    color: #FFF;
    font-family: var(--inter);
    font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  
  .testimonials-navigation {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .testimonial-prev, .testimonial-next {
    border: none;
    color: white;
    font-size: 24px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    
  }
  .testimonial-prev {
    background: var(--grey, #545454);
  }
  .testimonial-next {
    background: var(--green, #57e0b2);

  }
  
  @media (max-width: 768px) {
    .testimonial-card {
      min-width: 80%;
    }
  }
  
  @media (max-width: 480px) {
    .testimonial-card {
      min-width: 100%;
    }
  }
  