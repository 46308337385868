@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
:root {
    /*========== Color ==========*/
    --dark-grey: #272727;
    --white : #F9F9F9;
    --green: #57E0B2;
  
    /*========== Font family ==========*/
    --inter: "Inter", sans-serif;
    --montserrat: "Montserrat", sans-serif;
  }

  /*==================== BASE ====================*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  
  html {
    scroll-behavior: smooth;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  ul {
    list-style: none;
  }
  
  a {
    text-decoration: none;
  }
  input:focus {
    outline: none;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }

  /*==================== RESPONSIVE ====================*/
  @media only screen and (max-width: 768px) {

  }
  
  @media only screen and (min-width: 769px) and (max-width: 1150px) {
  
  }