.portfoliosection-section {
  display: flex;
  width: 100%;
  padding: 173px 8%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.portfoliosection-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  width: 100%;
}
.portfoliosection-top-title {
  color: var(--dark-grey, #272727);
  font-family: Montserrat;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 600px;
}
.portfoliosection-top-title-green {
  color: var(--green, #57e0b2);
  font-family: Montserrat;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.portfoliosection-cta-green {
    border-radius: 50px;
    background: var(--green, #57e0b2);
    display: flex;
    padding: 15px 30px;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
    color: var(--white, #f9f9f9);
    font-family: var(--inter);
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.portfoliosection-cta-green i {
    font-size: 23px;
    padding-top: 2px;
}
.portfoliosection-bottom {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
}

.portfolio-slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  overflow: hidden;
}

.portfolio-slide {
  min-width: 33.3333%;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  filter: drop-shadow(6px 9px 11px rgba(0, 0, 0, 0.10));
  
}

.portfolio-slide-content {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}

.portfolio-slide-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 15px;
}

.portfolio-slide-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(22, 56, 45, 0.85);
  color: white;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  padding: 20px;
  border-radius: 15px;
  gap: 25px;
}

.portfolio-slide-content:hover .portfolio-slide-overlay {
  opacity: 1;
}

.portfolio-slide-title {
    color: var(--white, #F9F9F9);
    text-align: center;
    font-family: var(--montserrat);
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.portfolio-slide-description {
    color: var(--white, #F9F9F9);
    text-align: center;
    font-family: var(--inter);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -10px;
}

.portfolio-slide-labels {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  
}

.portfolio-slide-label {
  background: rgba(0, 0, 0, 0.5);
  padding: 7px 15px;
  border-radius: 15px;
  font-size: 14px;
  font-family: var(--inter);
}

.portfolio-slide-button {
  background: var(--green, #57e0b2);
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  font-family: var(--inter);
  display: flex;
  align-items: center;
  gap: 5px;

}

.portfolio-prev,
.portfolio-next {
  background: none;
  border: none;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--white);
  z-index: 10;
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8.343px;
}

.portfolio-prev {
  left: 10px;
  border-radius: 66.747px;
  border: 7.662px solid #fff;
  background: var(--grey, #545454);
  margin-left: -40px;
}

.portfolio-next {
  right: 10px;
  border-radius: 66.747px;
  border: 7.662px solid #fff;
  background: var(--green, #57e0b2);
  margin-right: -40px;
}

@media (max-width: 768px) {
  .portfolio-slide {
    min-width: 50%;
  }
}

@media (max-width: 480px) {
  .portfolio-slide {
    min-width: 100%;
  }
}
