.newsletter-section {
    display: flex;
justify-content: space-between;
align-items: center;
align-self: stretch;
padding: 60px 15%;
gap: 25px;
}

.newsletter-left {
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 19.565px;
}

.newsletter-title {
    color: var(--dark-grey, #272727);
font-family: Montserrat;
font-size: 35px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;
}

.newsletter-title-green {
    color: var(--green, #57E0B2);
font-family: Montserrat;
font-size: 35px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;
}

.newsletter-text {
    color: var(--dark-grey, #272727);
font-family: var(--inter);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.newsletter-right {
}

.newsletter-right-box {
    border-radius: 59.61px;
border: 4px solid var(--green, #57E0B2);
display: flex;
flex-direction: row;
width: 400px;
align-items: center;
justify-content: space-between;

}

.newsletter-input {
    border: none;
    background: transparent;
    padding: 10px 25px;
}
.newsletter-input::placeholder {
    color: var(--grey, #545454);
    font-family: var(--inter);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.newsletter-btn {
    border-radius: 28px;
background: var(--green, #57E0B2);
color: var(--white, #F9F9F9);
font-family: var(--inter);
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: flex;
padding: 10px 25px;
justify-content: center;
align-items: center;
gap: 10.382px;
border: none;
margin-right: -1px;
}
.newsletter-btn:hover {
    cursor: pointer;
}

  /*==================== RESPONSIVE ====================*/
  @media only screen and (max-width: 768px) {

  }
  
  @media only screen and (min-width: 769px) and (max-width: 1150px) {
  
  }