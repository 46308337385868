body.contact-page {
  background-color: var(--dark-grey);
}
.contact-section {
  display: flex;
  align-items: center;
  gap: 269px;
  justify-content: center;
  padding: 140px 5%;

}

.contact-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 35px;
}

.contact-title {
  color: var(--white, #f9f9f9);
  font-family: Montserrat;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.contact-title-green {
  color: var(--green, #57e0b2);
  font-family: Montserrat;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.contact-left-text {
  color: var(--white, #f9f9f9);
  font-family: var(--inter);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contact-left-mail {
  display: flex;
  align-items: center;
  gap: 20px;
}

.contact-left-mail-text {
  color: var(--white, #f9f9f9);
  font-family: var(--inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contact-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 37px;
}

.contact-right-line {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  align-self: stretch;
}

.contact-right-line-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-right-line-box-title {
  color: var(--white, #f9f9f9);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 38.4px */
  letter-spacing: 0.48px;
}

.contact-right-line-box-title-green {
  color: var(--green, #57e0b2);
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  letter-spacing: 0.48px;
}

.contact-right-line-box-input {
  background: transparent;
  border-bottom: 1px solid var(--white);
}

.contact-right-line-big-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-right-line-box-textarea {
  background: transparent;
  border-bottom: 1px solid var(--white);
}
.contact-right-line-box-textarea::placeholder {
  background: transparent;
  border-bottom: 1px solid var(--white);
  color: var(--white, #f9f9f9);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%; /* 28.8px */
  letter-spacing: 0.36px;
}

.contact-right-line-nl {
  display: flex;
  align-items: center;
  gap: 119px;
  align-self: stretch;
}
.contact-right-line-nl-text {
  color: var(--white, #f9f9f9);
  font-family: var(--inter);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.checkbox:checked {
  background-color: var(--green);
  border: none;
  background-clip: content-box;
}
.contact-right-submit-btn {
  display: flex;
  padding: 8.372px 20.93px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 41.86px;
  background: var(--green, #57e0b2);
  color: var(--white, #f9f9f9);
  font-family: var(--inter);
  font-size: 30.14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
