.servicessection-section {
    border-radius: 50px;
    background: var(--dark-grey, #272727);
    display: flex;
width: 100%;
padding: 173px 8%;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 50px;
}
.servicessection-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid var(--white);
    gap: 25px;padding-bottom: 25px;
}
.servicessection-title {
    color: var(--white, #F9F9F9);
    font-family: var(--montserrat);
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.servicessection-title-green {
    color: var(--green, #57E0B2);
    font-family: var(--montserrat);
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.servicessection-text {
    color: var(--white);
    font-family: var(--inter);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    max-width: 600px;
}
.servicessection-bottom {
    display: flex;
justify-content: center;
align-items: center;
gap: 20px;
align-self: stretch;
}
.servicessection-box {
display: flex;
flex-direction: column;
position: relative;
max-width: 500px;

}
.servicessection-bg-grey {
    border-radius: 38.114px 38.114px 0px 0px;
background: var(--grey, #545454);
padding: 35px 5% 180px 5%;
display: flex;
flex-direction: column;
gap: 20px;
width: 100%;
height: 300px;

}
.servicessection-bg-green {
    border-radius: 38.114px 38.114px 0px 0px;
background: var(--green);
padding: 35px 5% 180px 5%;
display: flex;
flex-direction: column;
gap: 20px;
width: 100%;
height: 300px;
}
.servicessection-box-title-box {
    display: flex;
    align-items: center;
    gap: 15px;
}
.servicessection-box-icon-green {
    color: var(--green);
    font-family: var(--montserrat);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.servicessection-box-icon-grey {
    color: var(--dark-grey);
    font-family: var(--montserrat);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.servicessection-box-title {
    color: var(--white, #F9F9F9);
    font-family: var(--montserrat);
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.servicessection-box-text {
    color: var(--white);
    font-family: var(--inter);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.servicessection-img {
width: 100%;
height: auto;
margin-top: -140px;

}
.servicessection-btn-grey {
    border-radius: 60.983px;
    background: var(--grey, #545454);
    display: flex;
    width: 90px;
    height: 90px;
padding: 28.205px 28.967px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 7.623px;
flex-shrink: 0;
color: var(--white);
font-size: 35px;
position: absolute;
right: 10px;
bottom: 0;
}

.servicessection-btn-grey i,
.servicessection-btn-green i {
    padding-top: 5px;
}
.servicessection-btn-green {
    border-radius: 60.983px;
    background: var(--green, #545454);
    display: flex;
    width: 90px;
    height: 90px;
padding: 28.205px 28.967px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 7.623px;
flex-shrink: 0;
color: var(--white);
font-size: 35px;
position: absolute;
right: 10px;
bottom: 0;
}


@media only screen and (max-width: 768px) {
    .servicessection-bottom {
        flex-direction: column;
    }
    .servicessection-top {
        flex-direction: column;
        align-items: flex-start
    }
    .servicessection-section {
        padding: 60px 5%;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1150px) {
    
}